<template>
  <v-card class="mx-auto mb-10">
    <v-card-title class="font-weight-bold">
        Off Air
    </v-card-title>
    <v-divider />
    <div class="skeleton-list-item w-full" v-if="hourlyReportLoading">
        <v-skeleton-loader  :loading="true" type="image"></v-skeleton-loader>
    </div>
    <div class="ma-0 pa-9 text-center" v-if="!hourlyReportLoading && OffAirSummary.length == 0">
        Donation Not Found!.
    </div>
    <v-simple-table class="pa-4" v-if="!hourlyReportLoading && OffAirSummary">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center">This Hour</th>
            <th class="text-center">Past Hour</th>
            <th class="text-center">2 Hours Ago</th>
          </tr>
        </thead>
        <tbody class="simple-table-borders">
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-right border-left">Once</td>
                <td class="text-right border-right font-weight-bold">No. of donations added to the tally</td>
                <td class="text-center border-right">
                    {{OffAirSummary.current && OffAirSummary.current.once_count ? OffAirSummary.current.once_count : 0}}
                </td>
                <td class="text-center border-right">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].once_count ? OffAirSummary['1_hr_ago'].once_count : 0}}
                </td>
                <td class="text-center border-right">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].once_count ? OffAirSummary['2_hr_ago'].once_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations added to the tally</td>
                <td class="text-center border-right">
                    {{OffAirSummary.current && OffAirSummary.current.once_amount ? OffAirSummary.current.once_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].once_amount ? OffAirSummary['1_hr_ago'].once_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].once_amount ? OffAirSummary['2_hr_ago'].once_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr class="accent lighten-4">
                <td rowspan="2" class="text-center border-top border-right border-left">Recurring</td>
                <td class="text-right border-right border-top border-top font-weight-bold">No. of donations added to the tally</td>
                <td class="text-center border-right border-top">
                    {{OffAirSummary.current && OffAirSummary.current.rec_count ? OffAirSummary.current.rec_count : 0}}
                </td>
                <td class="text-center border-right border-top">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].rec_count ? OffAirSummary['1_hr_ago'].rec_count : 0}}
                </td>
                <td class="text-center border-top border-right">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].rec_count ? OffAirSummary['2_hr_ago'].rec_count : 0}}
                </td>
            </tr>
            <tr class="accent lighten-4">
                <td class="text-right border-right font-weight-bold">$ value of donations received</td>
                <td class="text-center border-right">
                    {{OffAirSummary.current && OffAirSummary.current.rec_amount ? OffAirSummary.current.rec_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].rec_amount ? OffAirSummary['1_hr_ago'].rec_amount : 0}}
                </td>
                <td class="text-center border-right">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].rec_amount ? OffAirSummary['2_hr_ago'].rec_amount : 0}}
                </td>
            </tr>
            <tr style="height: 30px"></tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentive Donations</td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary.current && OffAirSummary.current.incentive_donation ? OffAirSummary.current.incentive_donation : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].incentive_donation ? OffAirSummary['1_hr_ago'].incentive_donation : 0}}
                </td>
                <td class="text-center border-none">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].incentive_donation ? OffAirSummary['2_hr_ago'].incentive_donation : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of new Donors</td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary.current && OffAirSummary.current.new_donors ? OffAirSummary.current.new_donors : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].new_donors ? OffAirSummary['1_hr_ago'].new_donors : 0}}
                </td>
                <td class="text-center border-none">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].new_donors ? OffAirSummary['2_hr_ago'].new_donors : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No of Incentives</td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary.current && OffAirSummary.current.incentive_count ? OffAirSummary.current.incentive_count : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].incentive_count ? OffAirSummary['1_hr_ago'].incentive_count : 0}}
                </td>
                <td class="text-center border-none">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].incentive_count ? OffAirSummary['2_hr_ago'].incentive_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">No. of donations added the inspiration fund</td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary.current && OffAirSummary.current.inspiration_count ? OffAirSummary.current.inspiration_count : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].inspiration_count ? OffAirSummary['1_hr_ago'].inspiration_count : 0}}
                </td>
                <td class="text-center border-none">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].inspiration_count ? OffAirSummary['2_hr_ago'].inspiration_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ value of donations added to the inspiration fund</td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary.current && OffAirSummary.current.inspiration_amount ? OffAirSummary.current.inspiration_amount : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].inspiration_amount ? OffAirSummary['1_hr_ago'].inspiration_amount : 0}}
                </td>
                <td class="text-center border-none">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].inspiration_amount ? OffAirSummary['2_hr_ago'].inspiration_amount : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">Total no. of donations</td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary.current && OffAirSummary.current.donation_count ? OffAirSummary.current.donation_count : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].donation_count ? OffAirSummary['1_hr_ago'].donation_count : 0}}
                </td>
                <td class="text-center border-none">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].donation_count ? OffAirSummary['2_hr_ago'].donation_count : 0}}
                </td>
            </tr>
            <tr>
                <td class="border-none"></td>
                <td class="text-right border-right font-weight-bold border-none">$ Total value of donations</td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary.current && OffAirSummary.current.donation_amount ? OffAirSummary.current.donation_amount : 0}}
                </td>
                <td class="text-center border-right border-none">
                    {{OffAirSummary['1_hr_ago'] && OffAirSummary['1_hr_ago'].donation_amount ? OffAirSummary['1_hr_ago'].donation_amount : 0}}
                </td>
                <td class="text-center border-none">
                    {{OffAirSummary['2_hr_ago'] && OffAirSummary['2_hr_ago'].donation_amount ? OffAirSummary['2_hr_ago'].donation_amount : 0}}
                </td>
            </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['hourlyReportLoading', 'OffAirSummary'])
  }
}
</script>
